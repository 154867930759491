import GATSBY_COMPILED_MDX from "/workspace/src/content/post-7-new-job.mdx";
import React from "react";
import {Link} from "gatsby";
import {Layout, ShareLinks} from "../components";
import Share from '../assets/share.svg';
import XMark from '../assets/xmark.svg';
import {Dialog} from "@headlessui/react";
var shortcodes = {
  Link: Link
};
function PageTemplate(_ref) {
  var _mdx$frontmatter, _frontmatter$title;
  var data = _ref.data, children = _ref.children;
  var mdx = data.mdx;
  var frontmatter = (_mdx$frontmatter = mdx === null || mdx === void 0 ? void 0 : mdx.frontmatter) !== null && _mdx$frontmatter !== void 0 ? _mdx$frontmatter : null;
  var crumbs = [{
    label: 'Home',
    to: '/'
  }, {
    label: 'Blog',
    to: '/blog'
  }, {
    label: (_frontmatter$title = frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.title) !== null && _frontmatter$title !== void 0 ? _frontmatter$title : ''
  }];
  var _React$useState = React.useState(false), isOpen = _React$useState[0], setIsOpen = _React$useState[1];
  var setOpenHandler = function setOpenHandler(event) {
    event.preventDefault();
    setIsOpen(true);
  };
  return React.createElement(React.Fragment, null, React.createElement(Layout, {
    breadcrumbs: crumbs
  }, React.createElement("div", {
    className: "prose prose-slate w-full"
  }, React.createElement("h1", {
    className: "mt-5 mb-2"
  }, frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.title), React.createElement("div", {
    className: "flex min-w-full flex-row items-center justify-between"
  }, React.createElement("span", null, frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.date), React.createElement(Link, {
    onClick: function onClick(e) {
      return setOpenHandler(e);
    },
    className: "inline-flex items-center hover:underline",
    to: "/blog" + (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.slug) + "/share"
  }, React.createElement(Share, {
    className: "mr-2 h-4"
  }), "Share")), children)), React.createElement(Dialog, {
    as: "div",
    className: "fixed top-0 z-10 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50",
    open: isOpen,
    onClose: function onClose() {
      return setIsOpen(false);
    }
  }, React.createElement(Dialog.Panel, {
    className: "w-full max-w-md flex-col items-center justify-between rounded-lg bg-white p-6 shadow-xl"
  }, React.createElement("div", {
    className: "flex flex-row items-start justify-between"
  }, React.createElement(Dialog.Title, {
    as: "h1",
    className: "mb-3 text-xl text-slate-900"
  }, "Share ", React.createElement("span", {
    className: "text-slate-500"
  }, "/")), React.createElement("button", {
    onClick: function onClick() {
      return setIsOpen(false);
    }
  }, React.createElement(XMark, {
    className: "h-4"
  }))), React.createElement(ShareLinks, {
    title: "Elliot Jordan Kemp" + ((frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.title) && " | " + (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.title)),
    url: "https://elliotjordankemp.com/blog" + (frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.slug)
  }))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var query = "4219600655";
